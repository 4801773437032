import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import GreenKitchen from 'images/GreenKitchen.webp'

const Container = tw.div`relative py-8 px-4 sm:px-0`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto md:pt-12 lg:pt-16 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-2 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const RightColumn = styled.div`
  background-image: url("${props => props.imageSrc}");
  ${tw`bg-green-500 bg-cover bg-center w-96  h-96 lg:h-96 lg:w-full lg:flex-1`}
`;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw.h1`text-2xl sm:text-5xl md:text-6xl lg:text-5xl font-normal leading-none uppercase text-gray-900`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-base font-medium leading-relaxed text-gray-800`;

const Actions = styled.div`
  ${tw`my-8 mx-auto lg:mx-auto`}

  .action {
    ${tw`text-center text-gray-800 w-1/2 sm:w-full sm:w-64 py-4 font-light tracking-wide hocus:outline-none focus:shadow-outline transition duration-500 inline-block flex items-center justify-center mx-auto relative overflow-hidden`}

    &:before {
      ${tw`absolute top-0 left-0 w-full h-full bg-gray-100 transition duration-500 -z-10`}
      content: "";
      transform: translateX(-100%);
    }

    &:hover:before {
      transform: translateX(0%);
    }
  }
  .primaryAction {
    ${tw`border-2 text-white hover:text-gray-900 font-semibold mx-auto relative z-20`}
    background-color: transparent;
  }
  svg {
    ${tw`ml-2 w-4 h-4`}
  }
`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by Professionals.
    </>
  ),
  primaryButtonUrl= false,
  description = "Welcome to Timeless Tile Inc, your premier provider of top-quality tile installation services. With over a decade of experience in the industry, we have earned a reputation for excellence and meticulous attention to detail. Our team of skilled professionals is committed to delivering exceptional results, no matter the size or complexity of the project. Trust us to bring your vision to life with precision and care.",
  primaryButtonText = "Learn More",
  imageSrc = GreenKitchen,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {primaryButtonUrl &&
            <Actions>
            <a href={primaryButtonUrl} className="action primaryAction" aria-label="about-page-button">
              {primaryButtonText}
              <ArrowRightIcon/>
            </a>
          </Actions>}
            
          </TextContent>
        </TextColumn>
        <RightColumn imageSrc={imageSrc} loading="lazy" alt="kitchen-about-us">
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};
