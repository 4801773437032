import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import GreenKitchen from 'images/GreenKitchen.webp'
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import Gil from "images/gil.webp"

const Container = tw.div`relative py-8 sm:px-0 sm:py-12`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between mx-auto mt-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 md:mt-0 bg-gray-900 flex flex-col justify-center items-center p-6 py-24 lg:p-32`,
  props.textOnLeft ? tw`md:order-first` : tw` md:order-first`
]);
const RightColumn = styled.div`
  background-image: url("${props => props.imageSrc}");
  ${tw`bg-green-500 bg-cover bg-center h-1/3 lg:h-9/12 lg:w-full lg:flex-1`}
`;

const Subheading = tw(SubheadingBase)`mb-4 uppercase text-center`;
const HeadingTitle = tw.h1`text-4xl sm:text-5xl md:text-6xl lg:text-6xl lg:max-w-xl mx-auto font-normal font-serif leading-none text-gray-800 text-center`;
const Heading = tw.h1`text-base lg:text-lg font-bold leading-none  uppercase text-center text-beige`;
const Description = tw.p`mt-4 text-center text-sm md:text-base lg:text-base font-medium lg:font-bold leading-relaxed text-beige`;

const Actions = styled.div`
  ${tw`mt-8 lg:my-8 mx-auto lg:mx-auto`}

  .action {
    ${tw`text-center text-gray-800 w-full w-32 lg:w-64 py-4 font-light tracking-wide hocus:outline-none focus:shadow-outline transition duration-500 inline-block flex items-center justify-center mx-auto relative overflow-hidden`}

    &:before {
      ${tw`absolute top-0 left-0 w-full h-full bg-gray-100 transition duration-500 -z-10`}
      content: "";
      transform: translateX(-100%);
    }

    &:hover:before {
      transform: translateX(0%);
    }
  }
  .primaryAction {
    ${tw`border text-white hover:text-gray-900 text-sm font-semibold mx-auto relative z-20`}
    background-color: transparent;
  }
  svg {
    ${tw`ml-2 w-4 h-4`}
  }
`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by Professionals.
    </>
  ),
  primaryButtonUrl = '/about',
  description = "We create unique tile installations that reflect your personal style. Our team handpicks each tile for durability and aesthetic appeal. Our collection offers a timeless and contemporary appeal, blending classic and modern aesthetics. We're committed to providing unparalleled quality and service.",
  primaryButtonText = "Learn More",
  imageSrc = Gil,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <Subheading>{subheading}</Subheading>
      <HeadingTitle>{heading}</HeadingTitle>
      <TwoColumn>
        <RightColumn imageSrc={imageSrc} loading="lazy" alt="kitchen-about-us">
        </RightColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <Heading>OUR PHILOSOPHY</Heading>
          <Description>{description}</Description>
          <Actions>
            <a href={primaryButtonUrl} className="action primaryAction" aria-label="about-page-button">
              DISCOVER
            </a>
          </Actions>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
