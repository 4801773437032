import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import Gil from "images/gil.webp"
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";


const Container = tw.div`relative py-8 px-4 sm:px-0 sm:py-12`;
const Content = tw.div`max-w-screen-xl mx-auto px-4 `;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-4 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;

const Heading = tw.h1`text-4xl sm:text-5xl md:text-6xl lg:text-6xl lg:max-w-xl mx-auto font-normal font-serif leading-none text-gray-800`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-800 mt-8`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center `
]);


const Subheading = tw(SubheadingBase)`mb-4 uppercase text-left`;
const Actions = styled.div`
  ${tw`my-8`}

  .action {
    ${tw`text-center mx-auto sm:mx-0 text-gray-800 w-1/2 sm:w-full sm:w-64 py-4 font-light tracking-wide hocus:outline-none focus:shadow-outline transition duration-500 inline-block flex items-center justify-center relative overflow-hidden`}

    &:before {
      ${tw`absolute top-0 left-0 w-full h-full bg-gray-900 transition duration-500 -z-10`}
      content: "";
      transform: translateX(-100%);
    }

    &:hover:before {
      transform: translateX(0%);
    }
  }
  .primaryAction {
    ${tw`border border-gray-900 text-gray-900 hover:text-gray-100 font-semibold relative z-20`}
    background-color: transparent;
  }
  svg {
    ${tw`ml-2 w-4 h-4`}
  }
`;

export default ({
  subheading = "Our Portfolio",
  headingHtmlComponent = (
    <>
      We've done some amazing projects.
    </>
  ),
  description = "We've achieved incredible projects, leaving a remarkable impact. Our team's dedication and creativity continue to drive excellence in everything we do.",
  linkText = "View all Projects",
  cardLinkText = "Read Case Study",
  textOnLeft = true
}) => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1601719817866-8678c3d417e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
      company: "Tesla Inc.",
      type: "Ad Campaign",
      title: "Personalized Ad Campaign using Google AdWords",
      durationText: "90 Days Campaign",
      locationText: "New York"
    },
    {
      imageSrc: Gil,
      company: "Nestle",
      type: "SEO Marketing",
      title: "Ranking #1 for keywords like Chocolate, Snack",
      durationText: "180 Day Campaign",
      locationText: "Palo Alto"
    }
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Heading>{headingHtmlComponent}</Heading>
              <HeadingDescription>{description}</HeadingDescription>
              <Actions>
              <a href={'/projects'} className="action primaryAction">
                View All Projects
              </a>
            </Actions>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} alt={card.locationText} loading="lazy"/>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
