import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import Team from "images/team.png";

const HeadingContainer = tw.div`text-center`

const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-medium text-gray-800 leading-none uppercase`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-secondary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-800`}
  9
`

export default ({
  heading = "Meet Our Professionals",
  cards = [
    {
      imageSrc: Team,
      position: "Founder",
      name: "Jason Villeda"
    },
    {
      imageSrc: Team,
      position: "Co-Founder",
      name: "Carlos Villeda"
    },
    {
      imageSrc: Team,
      position: "Superintendent and Installer",
      name: "Ricky Arteaga"
    },
    {
      imageSrc: Team,
      position: "Installer",
      name: "Jimmy Villeda"
    },
    {
      imageSrc: Team,
      position: "Installer",
      name: "Yonifer Villeda"
    },
    {
      imageSrc: Team,
      position: "Installer",
      name: "Omar Aifan"
    },
    {
      imageSrc: Team,
      position: "Office Manager",
      name: "Jamie Garcia"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {heading && <Heading>{heading}</Heading> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} loading="lazy"/>
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
