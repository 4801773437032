import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Footer from "components/footers/SimpleFiveColumn.js";
import HeroSections from "components/hero/HeroSections";

export const ContentWithPaddingXl= tw.div`max-w-screen-xl mx-auto pb-4`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
${tw`relative z-1 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${Image} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 `}
      }
      ${PostText} {
        ${tw`pl-8 pr-5`}
      }
      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center`}
      }
      ${AuthorName} {
        ${tw`text-left text-gray-800 text-sm`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
${props => css`background-image: url("${props.imageSrc}");`}
${tw`h-64 sm:h-80 bg-center bg-cover `}
`;
const PostText = tw.div`flex-1 px-6 py-8`
const PostTitle = tw.h6`font-medium uppercase hover:underline transition duration-300 `;
const PostTitleCentered = tw.h6`font-medium uppercase text-center hover:underline transition duration-300 `;

const PostType = tw.h6`font-medium uppercase hover:underline transition duration-300 text-xs mb-2`;
const PostTypeCentered = tw.h6`font-medium uppercase text-center hover:underline transition duration-300 text-xs mb-2`;

const ButtonContainer = tw.div`flex justify-center mt-8`;
const LoadMoreButton = tw.button`border-2 text-white hover:bg-gray-900 hover:text-gray-100 font-semibold mx-auto text-center text-gray-800 w-1/2 sm:w-full sm:w-48 py-4 font-light tracking-wide hocus:outline-none focus:shadow-outline transition duration-300 inline-block flex items-center justify-center mx-auto`;

const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorName = tw.div`font-medium uppercase transition duration-300 text-xs my-2 text-center`;
export default ({
  headingText = "Blog Posts",
  posts = [
    {
      postImageSrc:
        "https://jicasa.es/wp-content/uploads/2022/10/Cocina-blanca.jpg",
      authorImageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      title: "OCTOBER IS KITCHEN & BATH MONTH",
      description:
        "Celebrate the heart of your home by giving it a little extra love this April. From small updates to full renovations, now is the perfect time to create a kitchen and bath that you'll love for years to come.",
      authorName: "Adam Cuppy",
      authorProfile: "Vlogger",
      url: "/",
      featured: true,
      date: 'APRIL 23, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc:
        "https://blog.emser.com/wp-content/uploads/2022/09/Passion_Azul_W32PASSAZ0909_RoomScene_01-1-scaled.jpg",
      title: "STANDOUT TILE SHAPES",
      authorName: "Aaron Patterson",
      url: "/",
      date: 'MARCH 17, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc:
        "https://blog.emser.com/wp-content/uploads/2022/07/Raku_Mustard_W32RAKUMU0312M_RoomScene_01-scaled.jpg",
      title: "Choosing the perfect Safaris in Africa",
      authorName: "Sam Phipphen",
      url: "/",
      date: 'FEBRUARY 8, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc:
        "https://kowalske.com/wp-content/uploads/2021/12/square-tile-backsplash.jpg",
      title: "13 BEAUTIFUL KITCHEN BACKSPLASH IDEAS",
      authorName: "Tony Hawk",
      url: "/",
      date: 'JANUARY 12, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1576698483491-8c43f0862543?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=348&q=80",
      title: "HOW TO CHOOSE QUALITY TILE",
      authorName: "Himali Turn",
      url: "/",
      date: 'DECEMBER 20, 2022',
      type: 'Design & style'
    },
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <HeroSections title={'Blog'} imgSrc="https://blog.emser.com/wp-content/uploads/2023/04/Splash_Moss-Picket_W88SPLAMO1014MPK_RoomScene_01-1-1536x1536.jpg"/>
      <Container>
        <ContentWithPaddingXl>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Image imageSrc={post.postImageSrc} />
                  <PostText>
                    {!post.featured ? <PostTypeCentered>{post.type}</PostTypeCentered> : <PostType>{post.type}</PostType>}
                    {!post.featured ? <PostTitleCentered>{post.title}</PostTitleCentered> : <PostTitle>{post.title}</PostTitle>}
                    {post.featured && <PostDescription>{post.description}</PostDescription>}
                    <AuthorName>{post.date}</AuthorName>
                  </PostText>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = () => (
  {
    postImageSrc:
      "https://kowalske.com/wp-content/uploads/2021/12/square-tile-backsplash.jpg",
    title: "13 BEAUTIFUL KITCHEN BACKSPLASH IDEAS",
    authorName: "Tony Hawk",
    url: "/",
    date: 'JANUARY 12, 2023',
    type: 'Design & style'
  });
