import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-gray-800`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8  bg-center bg-cover h-bigScreen min-h-144 px-8 sm:px-16`}
  background-image: url("${props => props.imageSrc}");
`;

const ContainerTriple = styled.div`
  ${tw`relative   bg-center bg-cover h-bigScreen min-h-144 px-8 sm:px-16 w-full sm:w-1/3`};
  background-image: url("${props => props.imageSrc}");
`;
const ContainerDoble = styled.div`
  ${tw`relative bg-center bg-cover h-bigScreen min-h-144 px-8 sm:px-16 w-full sm:w-1/2`};
  background-image: url("${props => props.imageSrc}");
`;
const OpacityOverlay = tw.div`z-10 absolute h-full inset-0 bg-black opacity-35`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;


const Content = tw.div`m-auto `;
const Heading = tw.h1`text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-normal leading-none text-white border-2 border-white py-8 px-12 sm:px-24 text-center`;

export default () => {

  return (
    <div>
      <Container imageSrc={'https://blog.emser.com/wp-content/uploads/2022/07/Inhale_Azul_W32INHAAZ0412_RoomScene_01.jpg'}>
        <OpacityOverlay />
        <HeroContainer>
          <StyledHeader home={true} />
          <Content>
            <Heading>Gallery </Heading></Content>
        </HeroContainer>
      </Container>
      <Container imageSrc={'https://d3prqoxewi1hpr.cloudfront.net/transforms/amazons3/home/1395528/84295_1d7ce.jpg'}>

        <OpacityOverlay />
      </Container>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <ContainerTriple imageSrc={'https://cdn.shopify.com/s/files/1/1127/8620/collections/backsplash_banner_3000x2000-scaled-e1613498509320.jpg?v=1665662323&width=2000'}>

          <OpacityOverlay />
        </ContainerTriple>

        <ContainerTriple imageSrc={'https://cdn.shopify.com/s/files/1/0189/0466/files/lume_2x9_collection_1_c6bee462-c083-490e-b611-040ad21c6298_800x800_crop_center.jpg?v=1639516968'}>

          <OpacityOverlay />
        </ContainerTriple>
        <ContainerTriple imageSrc={'https://cdn.shopify.com/s/files/1/1127/8620/collections/bathroom_banner_3000x2000-scaled.jpg?v=1665662320&width=2000'}>

          <OpacityOverlay />
        </ContainerTriple>
      </div>
      <Container imageSrc={'https://cdn.shopify.com/s/files/1/1127/8620/collections/kitchen_banner_3000x2000-scaled-e1613498475532.jpg?v=1665662320&width=2000'}>

        <OpacityOverlay />
      </Container>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <ContainerDoble imageSrc={'https://d3prqoxewi1hpr.cloudfront.net/transforms/314872551ed7ef08fc484f39b89c9371/88f00_7745d.jpg'}>

          <OpacityOverlay />
        </ContainerDoble>

        <ContainerDoble imageSrc={'https://d3prqoxewi1hpr.cloudfront.net/transforms/amazons3/banners/422151/2bfb5_6bdf7.jpg'}>

          <OpacityOverlay />
        </ContainerDoble>
      </div>
    </div>
  );
};
