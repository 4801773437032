import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import HeroSections from "components/hero/HeroSections";
import About from "images/GreenKitchen.webp";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <HeroSections title="About Us" imgSrc={'https://centsationalstyle.com/wp-content/uploads/2019/04/blue-tile-bright-bazaar-bathroom.jpg'}></HeroSections>
      <MainFeature1
        subheading={<Subheading>About Timeless Tile Inc.</Subheading>}
        heading="About Timeless Tile Inc."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        primaryButtonUrl="/blog"
        imageSrc={'https://assets.architecturaldigest.in/photos/60084c4b08ae763b9ae85b90/16:9/w_2560%2Cc_limit/Bangalore-home-Weespaces-kitchen-3-1366x768.jpg'}
        description="At Timeless Tile Inc, we are guided by our core values of honesty, integrity, and transparency. We believe in delivering the highest level of customer service and satisfaction, and we work closely with our clients to ensure that their needs and preferences are always met. Safety is also a top priority for us, and we strictly adhere to safety protocols on all job sites to protect our workers and our clients."
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc={'https://cdn.mos.cms.futurecdn.net/eDtXUrdg8gMPkTXeci9GFa.jpg'}
        description="We're here to shake things up in the design world. Our mission is to push boundaries and challenge the status quo. We believe in creating innovative and unique designs that inspire and delight our clients. With our team of talented and creative designers, we're ready to make our mark and leave a lasting impact on the industry.        "
        textOnLeft={true}
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Transforming Spaces with Exceptional Tiling Solutions"
        buttonRounded={false}
        primaryButtonUrl="/blog"
        primaryButtonText="Learn More"
        imageSrc={'https://http2.mlstatic.com/D_NQ_NP_2X_651392-MLA51193425100_082022-F.webp'}
        description="We don't just install tiles; we create immersive experiences that inspire and delight. Our dedication to exceptional craftsmanship and customer satisfaction sets us apart as the leading authority in the tiling industry. Join us on this journey as we revolutionize spaces and leave an indelible mark on the world of design."
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
