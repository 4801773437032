import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import MainFeature from "components/features/TwoColWithButton.js";
import AboutUs from "components/features/AboutUs";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import Services from "components/portfolio/Portfolio";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Services></Services>
    <AboutUs></AboutUs>
    <Portfolio></Portfolio>
    <Testimonial></Testimonial>
    <Blog></Blog>
    <ContactUsForm></ContactUsForm>
    <Footer></Footer>
  </AnimationRevealPage>
);
