import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-900 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-lg mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`mb-3 w-1/2 text-center md:w-1/3 mx-auto flex flex-col items-center`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-1/3 mb-10 md:mb-0 `;

const ColumnHeading = tw.h5`font-medium uppercase text-white`;

const LinkList = tw.ul`mt-4 text-white text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-white hocus:border-white pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start m-auto`;
const LogoImg = tw.img`w-12`;
const LogoText = tw.h5`ml-2 text-2xl font-light text-white`;
const SocialLinksContainer = tw.div`mt-4 flex gap-4 justify-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-white text-gray-900 border-b-2 border-transparent hover:border-white transition duration-300  `}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} alt="logoFooter"/>
            <LogoText>TIMELESS TILE INC.</LogoText>
          </LogoContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/">Home</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">About Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/projects">Our Projects</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/blog">Blog</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Contact</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">timeless@gmail.com</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">(951) 905-4140</Link>
            </LinkListItem>
            <LinkListItem>
              <SocialLinksContainer>
                <SocialLink href="https://www.facebook.com/people/Timeless-Tile-Inc/100068761251138/" aria-label="Facebook">
                  <FacebookIcon alt="Facebook Icon" />
                </SocialLink>
                <SocialLink href="https://www.instagram.com/timelesstileinc/" aria-label="Twitter">
                  <TwitterIcon alt="Twitter Icon" />
                </SocialLink>
                <SocialLink href="https://www.yelp.com/biz/timeless-tile-san-diego-3" aria-label="YouTube">
                  <YoutubeIcon alt="YouTube Icon" />
                </SocialLink>
              </SocialLinksContainer>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
