import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import PassionAzul from "images/PassionAzul.webp"

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-gray-800`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover h-2/3 min-h-144 h-bigScreen px-8 sm:px-16`}
  background-image: url(${PassionAzul});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;


const Content = tw.div`m-auto`;
const Heading = tw.h1`text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-none text-white text-center`;
const SubHeading = tw.h1`text-2xl sm:text-3xl md:text-3xl lg:text-4xl font-light leading-none text-white text-center my-4`;

const Actions = styled.div`
  ${tw`my-8 mx-auto lg:mx-auto`}

  .action {
    ${tw`text-center text-gray-800 w-1/2 sm:w-full sm:w-64 py-4 font-light tracking-wide hocus:outline-none focus:shadow-outline transition duration-500 inline-block flex items-center justify-center mx-auto relative overflow-hidden`}

    &:before {
      ${tw`absolute top-0 left-0 w-full h-full bg-gray-100 transition duration-500 -z-10`}
      content: "";
      transform: translateX(-100%);
    }

    &:hover:before {
      transform: translateX(0%);
    }
  }

  .primaryAction {
    ${tw`border text-white hover:text-gray-900 font-semibold mx-auto relative z-20`}
    background-color: transparent;
  }

  svg {
    ${tw`ml-2 w-4 h-4`}
  }
`;

export default ({
  heading = (
    <>
     TIMELESS TILE INC.
    </>
  ),
  primaryActionUrl = "tel:951-905-4140",
  primaryActionText = "CONTACT US",
}) => {

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader home={true}/>
        <Content>
            <Heading>{heading}</Heading>
            <SubHeading>TILING COMPANY</SubHeading>
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
            </Actions>
          </Content>
      </HeroContainer>
    </Container>
  );
};
