import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useRef } from 'react';
import marmol from "../../images/marmol.webp"
import emailjs from '@emailjs/browser';
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon-ligth.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon-ligth.svg";

const Container = tw.div`relative`;
const Content = tw.div``;
const FormContainer = styled.div`
  background-image: url(${marmol});
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-200 text-gray-800 relative `}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-gray-100 text-gray-100 text-base font-medium tracking-wide border py-2 border-gray-500 text-gray-900 hocus:border-gray-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between w-full mx-auto max-w-screen-xl`;
const Column = tw.div` flex flex-wrap w-full  justify-between`;
const ColumnText = tw.div` flex flex-col sm:px-8 w-full text-left`;
const InputContainer = tw.div`relative py-5 mt-6 flex w-full sm:w-45`;
const TextAreaContainer = tw.div`relative py-5 mt-6 w-full`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-medium text-sm text-gray-800 uppercase `;
const Input = tw.input`border-2 p-4 mt-2 w-full border-gray-800 bg-white text-gray-800`;
const TextArea = tw.textarea` border-2 h-24 sm:h-full resize-none w-full border-gray-800 bg-white`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 border border-gray-100 text-gray-100 font-bold tracking-wide  uppercase text-sm transition duration-300 bg-gray-900 transform hover:bg-gray-100 hover:text-gray-800  mx-auto`;

export const Header = tw.h1`text-3xl sm:text-4xl md:text-5xl lg:text-5xl font-normal leading-none uppercase text-left `;

const LinkList = tw.ul`mt-4 text-white text-sm font-medium`;
const LinkListItem = tw.li`mt-3 text-base font-medium`;
const Link = tw.a`border-b-2 text-gray-900 border-transparent hocus:text-white hocus:border-white pb-1 transition duration-300`;
const SocialLinksContainer = tw.div`mt-4 flex gap-4 justify-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-900 text-gray-100 border-b-2 border-transparent hover:border-white transition duration-300  `}
  svg {
    ${tw`w-4 h-4`}
  }
`;
export default () => {
  const form = useRef();
  const description = "Complete the form below to explore our services. We'll contact you promptly to begin collaborating. Let's work together!"
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_m5mao0p', 'template_943mjw3', form.current, '43FURwpUvy74JxSCU')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };
  return (
    <Container id="contact">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <form ref={form} onSubmit={sendEmail}>
              <TwoColumn>
                <ColumnText>
                  <Header>Get in Touch</Header>
                  <p tw="py-4">{description}</p>
                  <Column>
                    <LinkList>
                      <LinkListItem>
                        <Link href="#">timeless@gmail.com</Link>
                      </LinkListItem>
                      <LinkListItem>
                        <Link href="#">(951) 905-4140</Link>
                      </LinkListItem>
                      <LinkListItem>
                        <SocialLinksContainer>
                          <SocialLink href="https://www.facebook.com/people/Timeless-Tile-Inc/100068761251138/" aria-label="Facebook">
                            <FacebookIcon alt="Facebook Icon" />
                          </SocialLink>
                          <SocialLink href="https://www.instagram.com/timelesstileinc/" aria-label="Twitter">
                            <TwitterIcon alt="Twitter Icon" />
                          </SocialLink>
                          <SocialLink href="https://www.yelp.com/biz/timeless-tile-san-diego-3" aria-label="YouTube">
                            <YoutubeIcon alt="YouTube Icon" />
                          </SocialLink>
                        </SocialLinksContainer>
                      </LinkListItem>
                    </LinkList>
                  </Column>
                </ColumnText>
                <Column>
                  <TwoColumn>
                    <Column>
                      <InputContainer>
                        <Label htmlFor="name-input">Name</Label>
                        <Input id="name-input" type="text" name="name" />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="surname-input">Last Name</Label>
                        <Input id="surname-input" type="surname" name="surname" />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="email-input">E-mail</Label>
                        <Input id="email-input" type="email" name="email" />
                      </InputContainer>

                      <InputContainer>
                        <Label htmlFor="phone-input">Phone</Label>
                        <Input id="phone-input" type="phone" name="phone" />
                      </InputContainer>


                      <TextAreaContainer>
                        <Label htmlFor="message-input">Message</Label>
                        <TextArea id="message-input" type="message" name="message" />
                      </TextAreaContainer>
                      <SubmitButton type="submit" value="Submit">Submit</SubmitButton>

                    </Column>
                  </TwoColumn>
                </Column>
              </TwoColumn>
            </form>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};
