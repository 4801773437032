import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import MainPage from "pages/MainPage.js";
import Projects from "pages/Projects.js";
import About from "pages/AboutUs.js";
import BlogIndexPage from "pages/BlogIndex.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/projects" element={<Projects/>} />
          <Route path="/blog" element={<BlogIndexPage/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/" element={<MainPage />} />
        </Routes>
      </Router>
    </>
  );
}