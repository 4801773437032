import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "../../images/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-lg lg:mx-6 lg:my-2
  font-medium tracking-wide transition duration-300 uppercase
  pb-1 border-b-2 border-transparent hover:border-white hocus:text-white
`;
export const Link = tw.a` text-gray-800 text-left
  text-lg my-2   lg:my-0
  font-semibold tracking-widest transition duration-300
  pb-1 
`;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-secondary-500 text-gray-100
  hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(Link)`
  ${tw`flex items-center font-medium border-b-0 text-3xl! ml-0! font-normal`};

  img {
    ${tw`w-12 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between `;
export const TextMobile = tw.span`text-white text-lg md:text-xl`;
export const Text = tw.span` text-lg hidden md:inline-block md:text-xl`;
export const NavToggle = tw.button`
   z-30 focus:outline-none hocus:text-secondary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw` z-30 fixed top-0 inset-x-0 p-8 text-center text-white bg-gray-900`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" , home}) => {
  console.log(home)
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" width='48px' height='48px'/>
    </LogoLink>
  );
  const mobileLogo = (
    <LogoLink href="/" className="mx-auto">
      <img src={logo} alt="logo" width='48px' height='48px'/>
      <TextMobile >TIMELESS TILE INC.</TextMobile>
    </LogoLink>
  );
  
  const defaultLinks = [
    <NavLinks key={1}>
      {mobileLogo}
      <NavLink href="/">Home</NavLink>
      <NavLink href="/about">About</NavLink>
      <NavLink href="/projects">Portfolio</NavLink>
      <NavLink href="/blog">Blog</NavLink>
    </NavLinks>
  ];
  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ y: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} 
          className={showNavLinks ? "open" : "closed"}
          aria-label={showNavLinks ? "Close menu" : "Open menu"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6 " alt="Close icon"/> : <MenuIcon tw="w-6 h-6" alt="Menu icon" />}
        </NavToggle>
      </MobileNavLinksContainer>
      
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:flex`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:flex`
  },
  md: {
    mobileNavLinks: tw`md:flex`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:flex`
  },
  lg: {
    mobileNavLinks: tw`lg:flex`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:flex`
  },
  xl: {
    mobileNavLinks: tw`lg:flex`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:flex`
  }
};
